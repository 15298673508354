/* Small Logo */
.chartobserver-logo-small {

}

.text-logo-alt {
  color: var(--logo-secondary);
}

.text-logo-alt-landing-page {
  color: var(--color-logo-grey);
}

.small-logo-image {
  width: 50px;
}

/* Full Logo */
.chartobserver-logo-3xl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, "Inter", sans-serif;
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.chartobserver-logo-4xl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, "Inter", sans-serif;
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.chartobserver-logo-5xl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, "Inter", sans-serif;
  font-size: var(--font-size-5xl);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.chartobserver-logo-6xl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, "Inter", sans-serif;
  font-size: var(--font-size-6xl);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.chartobserver-logo-7xl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, "Inter", sans-serif;
  font-size: var(--font-size-7xl);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.chartobserver-logo-8xl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, "Inter", sans-serif;
  font-size: var(--font-size-8xl);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

.chartobserver-logo-9xl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, "Inter", sans-serif;
  font-size: var(--font-size-9xl);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}

