.bc-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bc-modal-container {
    max-width: 600px;
    min-width: 400px;
    flex-grow: 1;
}

.bc-modal {
    display: flex;
    flex-direction: column;
    /* background-color: var(--background-secondary); */
    background-image: url("../../images/Brag_Card_Option_3.png");
    background-size: cover;
    padding: var(--spacing-2);

    border-radius: 10px 10px 0px 0px;

}

.bc-modal-token {
    color: var(--text-dark-primary);
}

.bc-modal-share-icon {
    color: var(--text-dark-primary);
}

.bc-modal-trade-detail-title {
    color: var(--text-dark-primary);
}

.bc-modal-date-wrapper {
    margin-top: var(--spacing-1);
}

.bc-modal-referral {
    display: flex;
    flex-direction: row;
    background-color: #E9EDF2;
    padding: var(--spacing-2);

    justify-content: space-between;
    border-radius: 0px 0px 10px 10px;
}

.bc-modal-referral-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.bc-modal-qr {
    display: flex;
    max-height: 100%;
    background-color: white;
    border-radius: 10px;
    padding: var(--spacing-1);
}