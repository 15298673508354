
.strategies-content {
    display: grid;
    padding: var(--spacing-4);
    width: 100%;
    height: 100%;
    gap: 1rem;
}

.strategies-grid {
    display: grid;
    grid-template-columns: repeat(
        auto-fit, 
        minmax(500px, 1fr)
    );
    grid-template-rows: repeat(
        auto-fit,
        minmax(500px, 500px)
    );
    gap: var(--spacing-4);
}
