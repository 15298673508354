.trade-history-content {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-4);
  overflow-y: scroll;
}

@media screen and (min-width:769px) {
  .trade-history {
    display: flex;
    flex-direction: column;
  }
  
  .trade-history-content {
    display: flex;
    flex-direction: column;
    /* max-height: 1000px; */
    margin: var(--spacing-4);
  }
  
  .trade-history-title {
    margin-left: 1rem;
    margin-bottom: 0;
    font-size: 24px;
  }
  
  .trade-history-updated-time {
    width: 100%;
    text-align: end;
    font-style: italic;
    color: #4f4f4f;
    padding: 0;
  }
  
  .trade-history-table {
    width: 100%;
    /* height: 75%; */
    /* height: 150%; */
    min-height: 250px;
    /* background-color: pink; */
    padding: 0 var(--spacing-2);
  }
  
  .trade-history-error {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: var(--spacing-4) 0;
    font-style: italic;
  }
}