.th-table {
    margin-top: var(--spacing-4);
    display: flex;
    width: 100%;
    flex-direction: column;
    /* border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey); */
}

.th-row-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: var(--spacing-1) var(--spacing-3);
}

.th-row-wrapper:not(:last-child) {
    /* border-bottom: 1px solid var(--color-grey); */
}

.th-info-container {

}

.th-amount-container {
    text-align: right;
}

.th-row-title {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-regular);
    color: var(--text-primary);
}

.th-row-base {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    font-weight: var(--font-weight-medium);
}

/* Modal Styling */

.th-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: var(--background-primary);
}

.th-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-1);
    border-bottom: 1px solid var(--color-text-grey);
}

.th-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--spacing-2);
    color: var(--text-primary);
}

.th-modal-header-title {
    font-size: var(--font-size-2xl);
    color: var(--text-primary);
}

.th-modal-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--spacing-2);
}

.th-modal-row-title {
    font-size: var(--font-size-sm);
    color: var(--text-primary);
}

.th-modal-row-base {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
}