.tv-widget-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}


.tv-widget-grid-layout {
    display: grid;
    width: 80%;
    min-height: 60%;
    grid-template-columns: auto 275px;
    background-color: var(--background-trading);
    grid-template-rows: none;
}

.tv-widget-modal-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.tv-widget-modal-container-trade {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 200px;
    height: 100%;
    color: var(--text-primary);
    outline: solid var(--color-trading-outline) 1px;
    z-index: 1000;
}

.tv-widget-modal-container-trade-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
}

.tv-widget-trade-information-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
    outline: solid var(--color-trading-outline) 1px;
}

.tv-widget-trade-status-alert {
    display: flex;

    align-items: center;
    justify-content: center;
    margin-top: var(--spacing-2);
}