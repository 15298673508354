.spinner-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem 1rem 0rem;
  width: 30%;
  min-width: 265px;
  height: 90%;
  min-height: 165px;
  background-color: var(--background-secondary);
  box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.25);
}

.spinner-table-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--background-secondary);
  box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
}

.spinner {
  animation: rotate 2s linear infinite;
  /* margin: -25px 0 0 -25px; */
  z-index: 2;
  width: 50px;
  height: 50px;

  & .path {
    stroke: white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  & .path-grey {
    stroke: grey;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

.spinner-small {
  animation: rotate 2s linear infinite;
  /* margin: -25px 0 0 -25px; */
  z-index: 2;
  width: 24px;
  height: 24px;

  & .path {
    stroke: white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
