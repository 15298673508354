.page-five {
  background-color: var(--color-white);
  padding-top: 40px;
  padding-bottom: 40px;
}

.page-five-header {
  margin-bottom: 1em;
}

.page-five-card-grid {
  display: flex;
  gap: 24px 16px;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto 65px;
}

.plan {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
  padding: 1rem;
  /* justify-content: space-between; */
}

.plan--blue {
  background: linear-gradient(
    90deg,
    rgba(34, 114, 255, 1) 0%,
    rgba(13, 3, 195, 1) 100%
  );
}

.black-name {
  margin: 0;
  padding: 0;
}

.white-name {
  margin: 0;
  padding: 0;
  color: var(--color-white);
}

.plan-price {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.blue-price {
  margin: 0;
  padding: 0;
  color: var(--color-blue);
  font-size: 56px;
  font-weight: var(--font-weight-bold);
}

.white-price {
  margin: 0;
  padding: 0;
  color: var(--color-white);
  font-size: 56px;
  font-weight: var(--font-weight-bold);
}

.plan-price-month {
  margin: 0rem 1rem;
  padding: 0rem;
  color: var(--color-black);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.plan-price-month-white {
  margin: 0rem 1rem;
  padding: 0rem;
  color: var(--color-white);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.plan-price-container-subtext {
  margin: 0;
  padding: 0;
  color: var(--color-black);
  font-size: 16px;
  font-weight: var(--font-weight-regular);
}

.plan-price-container-subtext-white {
  margin: 0;
  padding: 0;
  color: var(--color-white);
  font-size: 16px;
  font-weight: var(--font-weight-regular);
}

.plan-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 1rem;
}

.info-bullet {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.check-icon-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  background-color: var(--color-blue);
  border-radius: 50%;
  max-width: 1.2rem;
  max-height: 1.2rem;
  min-width: 1.2rem;
  min-height: 1.2rem;
}

.check-icon-white {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue);
  background-color: var(--color-white);
  border-radius: 50%;
  max-width: 1.2rem;
  max-height: 1.2rem;
  min-width: 1.2rem;
  min-height: 1.2rem;
}

.info-bullet-text {
  margin-left: 0.5rem;
  color: var(--color-black);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
}
.info-bullet-text-white {
  margin-left: 0.5rem;
  color: var(--color-white);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
}

.plan-button-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 2rem 0;
}

.plan-button-blue {
  border: none;
  outline: none;
  color: var(--color-white);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  padding: 1rem 2rem;
  border-radius: 2rem;
  background: linear-gradient(
    90deg,
    rgba(34, 114, 255, 1) 0%,
    rgba(13, 3, 195, 1) 100%
  );
}

.plan-button-white {
  border: none;
  outline: none;
  color: var(--color-dark-blue);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  padding: 1rem 2rem;
  border-radius: 2rem;
}

.news-letter {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 4rem;
  margin-left: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
  border-radius: 1rem;
  background-color: var(--color-light-blue);
  justify-content: center;
}

.news-letter img {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 0;
}

.news-letter-content {
  display: flex;
  flex-direction: column;
  margin: 2rem 1rem;
  justify-content: space-between;
  z-index: 5;
}

.news-letter-content h1 {
  margin: 0 0 24px 0;
  padding: 0;
}
.news-letter-content p {
  margin-bottom: 1rem;
}

.call-to-action {
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
  justify-content: space-between;
  align-items: center;
}

.call-to-action input {
  border: none;
  outline: none;
  background-color: var(--color-white);
  color: var(--color-paragraph);
  width: 75%;
  height: 100%; /* match button height*/
  padding: var(--spacing-2);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  border-radius: 6px;
  margin-right: var(--spacing-2);
}

@media screen and (min-width: 1024px) {
  .page-five {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .page-five-header {
    font-size: var(
      --font-size-6xl
    ); /* design comp shows this title larger than others on desktop*/
  }

  .call-to-action {
    gap: 0;
    flex-direction: row;
    justify-content: flex-start;
  }
}

/* breakpoint-xl */
@media screen and (min-width: 1280px) {
  .page-five-card-grid {
    flex-direction: row;
    max-width: initial;
  }
}
