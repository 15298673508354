
.bc {
    display: flex;
    flex-direction: column;
    /* background-color: var(--background-secondary); */
    padding: var(--spacing-2);
    border-radius: 10px;
    margin: var(--spacing-1) 0;
    /* box-shadow: 0 0 10px var(--box-shadow-color); */
    /* box-shadow: none !important; */
    cursor: pointer;
}

.bc:hover {
    box-shadow: 0 0 10px var(--box-shadow-color-hover);
}

.bc--modal {
    display: flex;
    flex-direction: column;
    background-color: var(--background-secondary);
    padding: var(--spacing-2);
    border-radius: 10px 10px 0px 0px;
}

.bc-logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bc-share-icon {
    color: var(--text-primary);
    cursor: pointer;
}

.bc-header  {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: var(--spacing-2);
}

.bc-token {
    color: var(--text-primary);

}

.bc-method {
    margin-left: var(--spacing-1);
    background-color: var(--background-primary);
    color: var(--text-primary);
    padding: 4px;
    border-radius: 5px;
}

.bc-profit-green {
    color: var(--color-glass-green);
}

.bc-profit-red {
    color: var(--color-glass-red);
}


.bc-trade-detail-container {
    display: flex;
    flex-direction: row;
}

.bc-entry-wrapper, .bc-exit-wrapper, .bc-date-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-2);
}

.bc-exit-wrapper, .bc-date-wrapper {
    margin-left: var(--spacing-2);
}

.bc-trade-detail-title {
    color: var(--text-primary);
}

.bc-trade-detail-subtext {
    color: #E2A750;
}

@media screen and (min-width: 769px) {
    .bc {
        display: flex;
        flex-direction: column;
        /* background-color: var(--background-secondary); */
        /* padding: var(--spacing-2); */
        border-radius: 10px;
        min-width: 400px;
        /* margin-right: var(--spacing-1); */
    }
    
}