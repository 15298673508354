

.reset-password-text {
    color: var(--color-paragraph);
    font-size: var(--font-size-base);
    text-align: center;
}

.forgot-password-login-text {
    margin: var(--spacing-2) 0 0 0;
    text-decoration: underline;
    color: var(--color-paragraph);
    font-size: var(--font-size-base);
    cursor: pointer;
}