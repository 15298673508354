.button-group-container {
    display: flex;
    flex-direction: row;
    border: solid 1px var(--color-trading-outline);
    border-radius: 20px;
    background-color: #fff;

    /* gap: var(--spacing-2); */
}

.button-group-button-container {
    display: flex;
    flex: 1;
    padding: var(--spacing-2) var(--spacing-4);
    align-items: center;
    color: var(--color-grey);
}

.button-group-button-container-active {
    background-color: var(--color-primary-a0);
    color: #fff;
}

.button-group-button-container:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.button-group-button-container:not(:first-child) {
    border-left: solid 1px var(--color-trading-outline);
}

.button-group-button-container:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}