/* Standard input field style */
.standard-input-container {
  display: flex;
  background-color: var(--color-white);
  width: 100%;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.standard-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  padding-left: 25px;
  color: var(--color-blue);
  font-size: var(--font-size-sm);
}

.standard-input-invalid {
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 10px;
  border: solid 2px var(--color-error);
  padding-left: 25px;
  color: var(--color-blue);
  font-size: var(--font-size-sm);
}

/* Login Page Class "login-page-input" */
.login-page-input-container {
  display: flex;
  background-color: var(--color-white);
  width: 100%;
  /* height: 40px; */
  margin-top: 20px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
}

.login-page-input {
  width: 100%;
  height: 100%;
  border: 2px solid var(--color-paragraph);
  border-radius: 22px;
  padding: 25px;
  color: var(--color-blue);
  font-size: var(--font-size-base);
}

.login-page-input-invalid {
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 22px;
  border: solid 2px var(--color-error);
  padding: 25px;
  color: var(--color-blue);
  font-size: var(--font-size-base);
}