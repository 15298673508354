.footer {
  position: relative;
  width: 100%;
  background-color: #282842;
}

.footer-container {
  padding: 40px 16px;
}

.footer h1,
.footer h2,
.footer h3,
.footer p {
  padding: 0;
  margin: 0.5rem;
}

.footer h1 {
  color: var(--color-white);
  font-size: 50px;
  font-weight: var(--font-weight-bold);
}

.footer p,
.footer h3 {
  color: var(--color-paragraph);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
}

.footer h2 {
  color: var(--color-white);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.footer-logo {
  display: flex;
  flex-direction: column;
}

.footer-links-grid {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-links h3 {
  cursor: pointer;
}

.copyright-container {
  background-color: #000020;
  padding: 16px;
}

.copyright {
  display: flex;
  justify-content: space-between;
}

.copyright h4 {
  color: var(--color-light-blue);
  font-size: 16px;
  font-weight: var(--font-weight-regular);
}

@media screen and (min-width: 1024px) {
  .footer-container {
    padding: 80px 16px;
  }
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-logo p {
    max-width: 400px;
  }
}
