.page-four {
  background-color: var(--color-light-blue);
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

.page-four-header {
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.coming-soon {
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  color: var(--color-blue);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  padding: 1rem 2rem;
  border-radius: 2rem;
  max-width: 225px;
  position: relative;
  z-index: 1;
}

.coming-soon--mobile {
  display: flex;
  margin: 0 auto;
}

.coming-soon--desktop {
  display: none;
}

.page-four-sample-strategies {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Column 1 */

.page-four-column-one {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
}

.page-four-process {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1000;
  align-items: center;
  box-shadow: 2px 2px 4px 3px var(--box-shadow-color-hover);
  border-radius: 10px;
  padding: var(--spacing-1);
  background-color: var(--color-white);
}

.page-four-process:hover {
  transform: scale(1.025);
  cursor: pointer;
}

.process-number {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  border-radius: 50%;
  margin-right: 2rem;
}

.process-number h1 {
  font-size: 46px;
  color: var(--color-blue);
  font-weight: var(--font-weight-bold);
  margin: 0;
  padding: 0;
}

.process-content h2 {
  color: #262222;
  font-size: 26px;
  font-weight: var(--font-weight-bold);
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.process-content p {
  color: #363434;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  padding: 0;
  margin: 0;
}

/* Column 2 */
.page-four-column-two {
  display: flex;
}

.page-four-column-two-image-container {
  position: relative;
}

.sample-strategies-image {
  max-width: 700px;
  width: 100%;
  height: auto;
}

.page-four-background-image-top-right {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  z-index: 0;
  width: 25%;
  overflow: hidden;
}

.page-four-background-image-bottom-right {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 25%;
  overflow: hidden;
}

.page-four-background-image-bottom-right-img {
  transform: rotate(180deg);
}
@media screen and (min-width: 1024px) {
  .page-four {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .page-four-sample-strategies {
    flex-direction: row;
  }

  .page-four-column-one {
    gap: 36px 0;
  }
  .page-four-column-two {
    justify-content: flex-end;
  }

  .coming-soon--mobile {
    display: none;
  }

  .coming-soon--desktop {
    display: flex;
  }
}
