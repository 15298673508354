
.walkthrough-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.walkthrough-modal-content {
    width: 65vw;
    height: 80vh;
    background-color: var(--background-primary);
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
}

.walkthrough-modal-header {

}

.walkthrough-modal-header span {
    color: var(--color-blue);
}

.walkthrough-modal-body {
    max-width: 50%;
    max-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: visible;
    z-index: 1;
}

.walkthrough-modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    gap: 1rem;
}

.walkthrough-modal-checkbox {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}