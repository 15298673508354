.action-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
}

.action-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-weight: 700;

}
.buy-action-active {
  background-color: var(--background-trading-action-active);
  color: var(--color-trading-green);
  border-top: solid var(--color-trading-green) 5px;
}
.buy-action-inactive {
  background-color: var(--background-trading-action-inactive);
  color: var(--text-primary);
}

.sell-action-active {
  background-color: var(--background-trading-action-active);
  color: var(--color-trading-red);
  border-top: solid var(--color-trading-red) 5px;
}
.sell-action-inactive {
  background-color: var(--background-trading-action-inactive);
  color: var(--text-primary);
}

@media screen and (min-width: 769px) {

  .action-wrapper {
      width: 100%;
      height: 75px;
      display: flex;
      flex-direction: row;
    }
  
    .action-container {
      cursor: pointer;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      font-weight: 700;
    }

    .buy-action-active {
      background-color: var(--background-trading-action-active);
      color: var(--color-trading-green);
      border-top: solid var(--color-trading-green) 5px;
    }
    .buy-action-inactive {
      background-color: var(--background-trading-action-inactive);
      color: var(--text-primary);
    }
  
    .sell-action-active {
      background-color: var(--background-trading-action-active);
      color: var(--color-trading-red);
      border-top: solid var(--color-trading-red) 5px;
    }
    .sell-action-inactive {
      background-color: var(--background-trading-action-inactive);
      color: var(--text-primary);
    }
}