
.metric-card {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

.metric-card-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* Hegiht and width needed for Nivo Charts */
    /* width: 250px;
    height: 250px; */
}

.metric-card-text {
    color: var(--text-primary);
}