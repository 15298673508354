.reset-password-page {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-light-blue);
    justify-content: center;
    align-items: center;
}

.reset-password-background {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reset-password-email {
    color: var(--color-blue);
}

.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-even;
}

.reset-password-container h3 {
    margin-top: 10px;
}

.reset-password-container ul {
    font-size: 14px;

}

.matching-password-error {
    color: #cc0000;
    font-size: 14px;
    margin-top: 5px;
}
/* .reset-password-container input {
    border: none;
    outline: none;
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
} */

.reset-password-button-container {
    display: flex;
    width: 100%;
    margin-top: var(--spacing-2);
}

.back-to-login {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-2);
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (min-width: 769px) {
    .reset-password-page {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        background-color: grey;
        justify-content: center;
        align-items: center;
    }
    
    .reset-password-background {
        display: flex;
        flex-direction: column;
        width: 50%;
        min-width: 500px;
        /* height: 50%; */
        background-color: var(--color-light-blue);
        border-radius: 25px;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .reset-password-email {
        color: var(--color-blue);
    }
    
    .reset-password-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-even;
        width: 50%;
        height: 100%;
    }
    
    .reset-password-container h3 {
        margin-top: 10px;
    }
    
    .reset-password-container ul {
        font-size: 14px;
    
    }
    
    .matching-password-error {
        color: #cc0000;
        font-size: 14px;
        margin-top: 5px;
    }
    /* .reset-password-container input {
        border: none;
        outline: none;
        width: 100%;
        height: 50px;
        padding-left: 10px;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
    } */
    
    .back-to-login {
        display: flex;
        flex-direction: row;
        padding: var(--spacing-2);
        cursor: pointer;
        text-decoration: underline;
    }
}