@media screen and (max-width: 768px) {
  .topbar-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    /* border-bottom: solid 2px var(--color-line-grey); */
    background-color: var(--background-primary);
    color: var(--text-primary);
  }
  
  .topbar-icon {
    color: var(--text-primary);
    cursor: pointer;
  }
  
  .topbar-container {
    /* display: none; */
    margin: var(--spacing-3);
  }
  
  .topbar-button-container {
    display: none;
  }
  
  .topbar-divider {
    /* display: none; */
    border-style: solid;
    border-width: 2px 0px 0px 0px;
    border-color: var(--color-line-grey);
  }
}


@media screen and (min-width: 769px) {
  .topbar-wrapper {
    width: 100%;
    /* border-bottom: solid 2px var(--color-line-grey); */
  }
  .topbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: var(--spacing-1\.5) var(--spacing-3);
  }
  .topbar-title {
    color: var(--text-primary);
    /* font-size: var(--font-size-xl); */
    font-weight: var(--font-weight-medium);
    letter-spacing: 0.15px;
  }
  .topbar-icon {
    color: var(--text-primary);
    cursor: pointer;
  }

  .topbar-button-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
  }
  
  .topbar-divider {
    border-style: solid;
    border-width: 2px 0px 0px 0px;
    border-color: var(--color-line-grey);
  }
}
