.landing-page {
  background-color: var(--color-light-blue);
  overflow: hidden;
}
.landing-page-links {
  text-decoration: none;
}

.lp-bg-img {
  overflow: hidden;
}

.landing-page-padding {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

@media screen and (min-width: 1024px) {
  .landing-page {
    padding-top: 50px; /* Make room for LandingPageNavigation */
    flex-grow: 1;
  }
}
