.activity-title {
    font-weight: var(--font-weight-bold);
    /* margin-left: var(--spacing-1); */
}

.activity-container {
    /* margin: var(--spacing-2); */
    /* margin-top: var(--spacing-2); */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: var(--spacing-1);

    width: 100%;
    overflow: scroll;
}

.activity-closed-trade-info {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.activity-closed-trade-container {
    display: flex;
    flex-direction: row;
    /* max-height: 600px; */
    overflow-x: scroll;
    overflow-y: hidden;
    gap: var(--spacing-1);
    padding: var(--spacing-1);
    border-radius: 5px;
    /* box-shadow: 
        inset 0px 15px 13px -20px rgba(147,151,153,1), 
        inset 0px -15px 13px -20px rgba(147, 151, 153,1); */

}

.activity-wrapper {
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

@media screen and (max-width: 768px) {
    .activity-container {
        margin-top: var(--spacing-2);
    }
}

@media screen and (min-width: 769px) {
    /* .activity-wrapper {
        display: grid;
        margin-top: var(--spacing-2);
        grid-template-columns: repeat(auto-fit, minmax(300px, 500px));
        gap: var(--spacing-2);
    } */
    .activity-container {
        display: flex;
        flex-direction: column;
        /* margin: var(--spacing-2); */
        /* margin-top: var(--spacing-2);
        margin-left: 0; */
        height: 100%;
        width: 100%;
    }

    .activity-wrapper {
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
        /* Firefox and modern browsers */
        scrollbar-width: thin;
        /* padding-top: var(--spacing-1);
        padding-bottom: var(--spacing-1); */
    }


    /* Legacy Browsers */
    .activity-wrapper::-webkit-scrollbar {
        width: 8px;
    }
}