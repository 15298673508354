.page-three {
  background-color: var(--color-white);
  padding-top: 60px;
  padding-bottom: 60px;
  overflow-x: hidden; /* hide sparkles lolz*/
}

.flex-row {
  display: flex;
  flex-direction: column;
}

.page-three-column-one {
}

.page-three .section-heading {
  margin-bottom: 40px;
}

.page-three-column-one h1 {
  margin: 0 0 28px 0;
  padding: 0;
}

.page-three-column-one p {
  margin: 0;
  padding: 0;
}

.mobile-image {
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 36px auto;
  height: auto;
}

.desktop-image {
  display: none;
}

.trading-view-link {
  display: flex;
  flex-direction: row;
  gap: 0 22px;
  margin-bottom: 36px;
  box-shadow: 2px 2px 4px 3px var(--box-shadow-color-hover);
  padding: 20px 16px;
  background-color: var(--color-white);
  border-radius: 10px;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.sub-heading {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 36px;
  box-shadow: 2px 2px 4px 3px var(--box-shadow-color-hover);
  border-radius: 10px;
  padding: var(--spacing-1);
  cursor: pointer;

}

.trading-view-link:hover {
  transform: scale(1.05);
}

.sub-heading:hover {
  transform: scale(1.05);
}

.sub-heading img {
  width: 3rem;
  height: 3rem;
}

.sub-heading-content h2 {
  display: flex;
  color: var(--color-dark-blue);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  margin: 0;
  padding: 0;
  align-items: center;
}

.page-three-column-two {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.sparkles {
  display: none;
}

@media screen and (min-width: 1024px) {
  .page-three {
    padding-top: 200px;
  }

  .flex-row {
    flex-direction: row;
  }

  .page-three-column-one {
    width: 40%;
    padding-bottom: 100px;
  }

  .page-three-column-one h1 {
    font-size: var(--font-size-4xl);
    line-height: var(--line-height-tight);
  }

  .mobile-image {
    display: none;
  }

  .desktop-image {
    display: flex;
    max-width: 800px;
    width: 95%;
    height: auto;
    padding-right: 36px;
  }

  .sparkles {
    display: block;
    position: absolute;
    top: -30px;
    right: 28px;
    transform: translate(4rem, -4rem) rotate(10deg);
    scale: 90%;
  }
}
