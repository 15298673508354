/* Mobile Styles */

.title {
  color: var(--color-dark-blue);
}

.landing-page-navigation {
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 16px;
  overflow: hidden;
}

.landing-page-navigation h1 {
  margin: 0;
}

.landing-page-navigation li {
  list-style: none;
}

.header-nav {
  display: none;
  gap: 0 32px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-nav-item {
  cursor: pointer;
  margin: 0;
  font-size: var(--font-size-lg);
  line-height: 1em;
  font-weight: var(--font-weight-medium);
  color: var(--color-logo-grey);
  text-decoration: none;
  transition: color 0.2s;
}

.header-nav-item p {
  margin: 0;
  padding: 0;
}

.mobile-header-nav-item {
  color: var(--text-primary);
}

.header-nav-item--dashboard {
  color: var(--color-blue) !important;
}
.header-buttons {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 300px;
}

.mobile-side-container {
  position: fixed;
  top: 0px;
  opacity: 0;
  right: -100vw;
  /* Start off-screen */
  width: 100%;
  height: 100%;
  background-color: var(--background-secondary);
  color: var(--color-white);
  box-sizing: border-box;
  transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
  padding: 0;
  z-index: var(--max-z-index);
}

.mobile-side-container--open {
  right: 0;
  opacity: 1;
}

.mobile-header-nav {
  margin: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  list-style-type: none;
}

.mobile-header-nav-discord {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.mobile-header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px;
}
.close-button-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 87px; /* header height*/
  padding: 0 16px;
}

/* Desktop Styles */

@media screen and (min-width: 768px) {
  .landing-page-navigation {
    border-radius: 10px;
    padding: 35px;
  }

  .header-nav {
    display: flex;
    align-items: center;
  }

  .header-buttons {
    display: flex;
  }

  .hamburger-button-container {
    display: none;
  }

  .mobile-side-container {
    display: none;
  }
}
