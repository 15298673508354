.user-info-modal-overlay {
    position: fixed;
    z-index: var(--max-z-index);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

.dashboard-modal-header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.user-info-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-signout);
    border-radius: 1rem;
    min-height: 320px;
    /* width: 100%; */
    min-width: 540px;
    max-width: 540px;
    max-height: 75vh;
    overflow-y: auto;
}

.user-info-modal-content {
    width: 100%;
    padding: 0 24px 24px 24px;
}

.user-info-username-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin-bottom: var(--spacing-3);
    color: var(--text-primary);
}