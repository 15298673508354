.leaderboard-content {
    margin: var(--spacing-6);
    display: flex;
    flex-direction: column;
}

.leaderboard-header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

.leaderboard-time-select {
    display: flex;
    padding: var(--spacing-2);
    background-color: #fff;
    border-radius: 10px;
}

.leaderboard-top {
    margin-top: var(--spacing-4);  
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

@media screen and (min-width: 769px) {
    .leaderboard-content {
        margin: var(--spacing-6);
        display: flex;
        flex-direction: column;
    }
    
    .leaderboard-header {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
    }
    
    .leaderboard-time-select {
        display: flex;
        padding: var(--spacing-2);
        background-color: #fff;
        border-radius: 10px;
    }
    
    .leaderboard-top {
        margin-top: var(--spacing-4);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: var(--spacing-4);
    }
    
}

