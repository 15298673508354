.signup-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    background-color: var(--color-light-blue);

}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.signup-title {
    color: var(--color-logo-grey);
    font-size: var(--font-size-4xl);
}

.signup-current-user {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-current-user-text {
    font-size: var(--font-size-lg);
    color: var(--color-paragraph);
}

.signup-span {
    color: var(--color-blue);
    text-decoration: underline;
    cursor: pointer;
}

.signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: var(--spacing-6);
    /* justify-content: space-evenly; */
    width: 90%;
    height: 100%;
    
}

.checkbox-container {
    display: flex;
    flex-direction: column;
}

.checkbox-wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
    margin-top: var(--spacing-2);
    color: var(--color-paragraph);
}
.checkbox-invalid {
    outline: 2px solid var(--color-error);
}

@media screen and (min-width: 769px) {
    
    .signup-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        background-color: var(--color-light-blue);
    }
    
    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
    }
    
    .signup-title {
        color: var(--color-logo-grey);
        font-size: var(--font-size-4xl);
    }
    
    
    .signup-current-user {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .signup-current-user-text {
        font-size: var(--font-size-lg);
        color: var(--color-paragraph);
        font-weight: var(--font-weight-semi-bold);
    }
    
    .signup-span {
        color: var(--color-blue);
        text-decoration: underline;
        cursor: pointer;
    }
    
    .signup-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: var(--spacing-6);
        /* justify-content: space-evenly; */
        width: 30%;
    }
}