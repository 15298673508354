.user-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.user-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-primary);
  cursor: pointer;
}

.user-name {
  margin-left: 1rem; /* 16px;*/
}

.user-login-button {
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi-bold);
  margin-left: 16px;
}
