.dashboard-links {
  overflow-y: auto;
}
.dashboard-links-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}
.dashboard-links-section-title {
  font-weight: var(--font-weight-medium);
  font-family: "Roboto", sans-serif;
  color: var(--link-header);
  text-transform: uppercase;
}

.dashboard-links-section-item {
  display: flex;
  gap: 32px;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  color: var(--text-primary);
  font-weight: var(--font-weight-regular);
  transition: color 0.2s ease-in-out;
}

.dashboard-links-section-item.active {
  color: var(--color-blue);
  font-weight: var(--font-weight-bold);
}

.external-link {
  cursor: pointer;
}


.dashboard-links-signout-section {
  padding-top: var(--spacing-4);
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 769px) {
  .dashboard-links-signout-section {
    display: none;
  }
}