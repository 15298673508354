@media screen and (max-width: 768px) {
  
  .manual-trade {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }

  .manual-trade-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }

  .manual-trade-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* justify-content: center; */
    align-items: center;
    padding-top: var(--spacing-2);
  }

  .manual-trade-dashboard {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50% 50%;
    /* grid-template-columns: auto 275px; */
    background-color: var(--background-trading);
    outline: solid var(--color-trading-outline) 1px;
  }

  .chart-container {
    display: grid;
    grid-template-rows: 75px auto;
    outline: solid var(--color-trading-outline) 1px;
    /* overflow: hidden; */
  }

  .chart-coin-select-container {
    display: flex;
    width: 100%;
    outline: solid var(--color-trading-outline) 1px;
    justify-content: center;
    align-items: center;
  }

  .chart-data-display {
    width: 100%;
    height: 100%;
  }

  .chart-display-boundary {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .manual-trade-action-container {
    position: relative;
    width: 100%;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-primary);
    outline: solid var(--color-trading-outline) 1px;
  }

  .available-wrapper {
    width: 100%;
    display: flex;
    outline: solid var(--color-trading-outline) 1px;
    justify-content: center;
    align-items: center;
  }

  .available-container-mobile {
    width: 100%;
    padding: var(--spacing-1) var(--spacing-2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    /* padding: 20px 0px; */
    
  }

  .available-container h4 {
    font-size: var(--font-size-xs);
  }

  .available-container p {
    font-size: var(--font-size-xs);
  }

  .available-token-wrapper {
    display: flex;
    width: 100%;
    /* margin-top: 20px; */
    flex-direction: row;
    justify-content: space-between;
  }

  .available-usd-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .trade-information-wrapper {
    width: 100%;
    /* padding: 20px 0px; */
    padding-top: var(--spacing-1);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: solid var(--color-trading-outline) 1px;
  }

  .trade-button-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: var(--spacing-1) 0;
  }

  .trade-button-container {
    width: 90%;
  }

  .trade-total-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--font-size-xs);
  }
  .trade-total-amount {
    display: flex;
    width: 100%;
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-2);
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--font-size-xs);
  }

  .manual-trade-action-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: left;
  }
  .manual-trade-action-footer p {
    padding-left: 5px;
    padding-bottom: 5px;
    font-size: var(--font-size-xxs);
  }
}


@media screen and (min-width: 769px) {
  
  .manual-trade {
    display: flex;
    flex-direction: column;
    flex-grow: 10;
  }
  
  .manual-trade-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .manual-trade-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */

  }
  
  .manual-trade-dashboard {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto 275px;
    grid-template-rows: none;
    background-color: var(--background-trading);
    outline: solid var(--color-trading-outline) 1px;
    /* box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.75); */
  }
  
  .chart-container {
    display: grid;
    grid-template-rows: 75px auto;
    outline: solid var(--color-trading-outline) 1px;
    /* overflow: hidden; */
  }
  
  .chart-coin-select-container {
    display: flex;
    width: 100%;
    outline: solid var(--color-trading-outline) 1px;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .chart-data-display {
    width: 100%;
    height: 100%;
  }
  
  .chart-display-boundary {
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .manual-trade-action-container {
    position: relative;
    width: 100%;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-primary);
    outline: solid var(--color-trading-outline) 1px;
  }
  
  .available-wrapper {
    width: 100%;
    display: flex;
    outline: solid var(--color-trading-outline) 1px;
    justify-content: center;
    align-items: center;
  }
  
  .available-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 20px 0px;
    
  }
  
  .available-container h4, p {
    font-size: var(--font-size-xs);
  }
  
  .available-token-wrapper {
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .available-usd-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .trade-information-wrapper {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: solid var(--color-trading-outline) 1px;
  }
  
  .trade-button-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 20px 0px;
  }
  
  .trade-button-container {
    width: 90%;
  }
  
  .trade-total-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--font-size-xs);
  }
  .trade-total-amount {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: var(--spacing-2);
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--font-size-xs);
  }
  
  .manual-trade-action-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: left;
  }
  .manual-trade-action-footer p {
    padding-left: 5px;
    padding-bottom: 5px;
    font-size: var(--font-size-xxs);
  }

}  
