
.webhook-url-instructions {
  display: flex;
  flex-direction: column;
}

.webhook-url-instructions-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: var(--spacing-4);
  color: var(--text-primary);
}

.webhook-info-title {
  font-weight: var(--font-weight-regular);
  margin-left: var(--spacing-2);
  margin-top: var(--spacing-4);
  text-decoration: underline;
  margin-bottom: var(--spacing-2);
}

.webhook-bullet {
  font-weight: var(--font-weight-regular);
  margin-left: var(--spacing-2);
}

.webhook-alert-container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-2);
}

.webhook-url-instructions-image {
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: var(--spacing-2);
}

.code-snippet {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--background-secondary);
  outline: 1px solid black;
  /* margin-top: var(--spacing-2); */
  border-radius: 10px;
  padding: var(--spacing-2);
  color: var(--text-primary);
}

.code-snippet-header {
  margin-bottom: var(--spacing-1);
}

.webhook-url-instructions-span {
  color: var(--color-blue);
}

@media screen and (min-width: 769px) {
  .webhook-url-instructions {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-4);
  }

  .webhook-url-instructions-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 500px;
    padding-bottom: var(--spacing-4);
    color: var(--text-primary);
  }

  .webhook-info-title {
    font-weight: var(--font-weight-regular);
    margin-left: var(--spacing-2);
  }

  .webhook-bullet {
    font-weight: var(--font-weight-regular);
    margin-left: 2rem;
  }

  .webhook-alert-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: var(--spacing-2);
    gap: var(--spacing-2);
    /* padding: 20px; */
    /* max-width: 100%; */
  }

  .webhook-url-instructions-image {
    max-width: 500px;
    border-radius: 10px;
  }

  .code-snippet {
    display: flex;
    flex-grow: 1;
    max-width: 500px;
    background-color: var(--background-secondary);
    outline: 1px solid black;
    /* margin-left: 10px; */
    border-radius: 10px;
    padding: var(--spacing-2);
    color: var(--text-primary);
  }

  .webhook-url-copy-container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    gap: var(--spacing-1)
  }

  .copy-icon:hover {
    color: var(--color-blue);
  }
}
