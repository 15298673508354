@media screen and (max-width: 768px) {
    .portfolio-metrics {
        margin: var(--spacing-4) 0;
    }

    /* .metrics-grid {
        display: flex;
        flex-direction: column;
        padding: 24px 0;
        gap: var(--spacing-2);
        margin-top: var(--spacing-2);
        margin-left: var(--spacing-1);
        margin-right: var(--spacing-1);

    } */
}
/* @media  screen and (min-width: 769px) {
    .metrics-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: var(--spacing-4);

    }
} */
