.account-info {
    display: flex;
    flex-direction: column;
    flex-grow: 10;
    height: 100vh;
}

.account-info-content {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    /* align-items: center; */
}

.account-info-content-compact {
    width: 100%;
    max-width: 700px;
    padding-top: var(--spacing-3);
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
}

.user-hero-banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--spacing-3);
}

.user-hero-info-wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    justify-content: center;
}

.user-hero-icon {
    color: var(--text-primary);
}

.user-hero-info-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-3);
    color: var(--text-primary);
}

.user-hero-info-column h1 {
    /* font-size: var(--font-size-3xl); */
    font-weight: var(--font-weight-semibold);
}

.user-hero-info-column p {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
}

.account-info-block {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.account-info-subtext-container {
    display: flex;
    align-items: center;
    background-color: #F9FAFC;
    width: 50%;
    height: 40px;
    margin-top: 10px;
}

.account-info-subtext {
    margin-left: 10px;
}

@media screen and (min-width: 768px) {
    .account-info {
        display: flex;
        flex-direction: column;
        flex-grow: 10;
        height: 100vh;
    }
    
    .account-info-content {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        /* align-items: center; */
    }
    
    .account-info-content-compact {
        width: 100%;
        max-width: 700px;
        padding-top: var(--spacing-3);
        margin: var(--spacing-4);
    }
    
    .user-hero-banner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: var(--spacing-3);
    }
    
    .user-hero-info-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .user-hero-icon {
        color: var(--text-primary);
    }
    
    .user-hero-info-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: var(--spacing-3);
        color: var(--text-primary);
    }
    
    .user-hero-info-column h1 {
        /* font-size: var(--font-size-3xl); */
        font-weight: var(--font-weight-semibold);
    }
    
    .user-hero-info-column p {
        font-size: var(--font-size-sm);
        color: var(--text-secondary);
    }
    
    .account-info-block {
        display: flex;
        flex-direction: column;
        margin: 10px;
    }
    
    .account-info-subtext-container {
        display: flex;
        align-items: center;
        background-color: #F9FAFC;
        width: 50%;
        height: 40px;
        margin-top: 10px;
    }
    
    .account-info-subtext {
        margin-left: 10px;
    }
}