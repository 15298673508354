.login-page {
    display: flex;
    width: auto;
    flex-direction: column;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 100px; */
    align-items: center;
    width: auto;
    background-color: var(--color-light-blue);
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.login-title {
    color: var(--color-logo-grey);
    font-size: var(--font-size-4xl);
}

.login-new-user {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-new-user-text {
    font-size: var(--font-size-lg);
    color: var(--color-paragraph);
    font-weight: var(--font-weight-semi-bold);
}

.login-span {
    color: var(--color-blue);
    text-decoration: underline;
    cursor: pointer;
}

.login-form {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-6);
    /* justify-content: center; */
    width: 90%;
    height: 100%;
}

.login-button-container {
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
}

.forgot-password-text {
    margin: var(--spacing-2) 0 0 0;
    text-decoration: underline;
    color: var(--color-paragraph);
    font-size: var(--font-size-base);
    cursor: pointer;
}

.invalid-credentials {
    margin-top: var(--spacing-1);
}

@media screen and (min-width: 769px) {
    .login-page {
        display: flex;
        width: auto;
        flex-direction: column;
    }

    .login-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin-top: 100px; */
        align-items: center;
        width: auto;
        background-color: var(--color-light-blue);
    }

    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
    }
    
    .login-title {
        color: var(--color-logo-grey);
        font-size: var(--font-size-4xl);
    }
    
    .login-new-user {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .login-new-user-text {
        font-size: var(--font-size-lg);
        color: var(--color-paragraph);
        font-weight: var(--font-weight-semi-bold);
    }
    
    .login-span {
        color: var(--color-blue);
        text-decoration: underline;
        cursor: pointer;
    }
    
    .login-form {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding-top: var(--spacing-6);
        width: 30%;
        height: 100%;
        min-width: 500px;
    }

    .login-button-container {
        margin-top: var(--spacing-2);
        margin-bottom: var(--spacing-2);
    }
}