.auth-content {
    width: 100vw;
    height: 100vh;
    background-color: var(--color-light-blue);
}

@media screen and (min-width: 769px) {

    .auth-nav-container {
        margin-top: var(--spacing-6);
    }
    
    .auth-content {
        width: 100vw;
        background-color: var(--color-light-blue);
    }
    
}