.trade-information-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}

.trade-order-wrapper-inactive {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border: solid var(--color-trading-outline) 1px;
    border-radius: 4px;
    background-color: var(--background-primary);
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-xs);
}

.trade-order-wrapper-active {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border: solid var(--color-blue) 1px;
    border-radius: 4px;
    background-color: var(--background-primary);
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-xs);
}

.trade-order-wrapper-invalid {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border: solid var(--color-error) 1px;
    border-radius: 4px;
    background-color: var(--background-primary);
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-xs);
}

.trade-order-input-amount {
    font-weight: 700;
}

.trade-order-input {
    display: flex;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    color: var(--text-primary);
    text-align: right;
    margin-right: 10px;
    margin-left: 10px;
    background-color: var(--background-primary);
    /* background-color: var(--background-trading); */
}

.trade-order-currency {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.trade-order-currency-swap {
    cursor: pointer;
}

.trade-order-token-conversion {
    display: flex;
    width: 100%;
    justify-content: right;
    color: var(--color-paragraph);
    padding-top: 5px;
}

.trade-order-quick-options {
    margin-top: var(--spacing-1);
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.trade-order-quick-options p {
    padding: calc(var(--spacing-1)/2) var(--spacing-1);
    border: 1px solid var(--color-trading-outline);
    border-radius: 25%;
}
.trade-order-quick-options p:hover {
    cursor: pointer;
    box-shadow: 0 0 5px var(--box-shadow-color-hover);
}

.trade-order-read-only-text {
    margin-top: var(--spacing-1);
}