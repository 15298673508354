
@media screen and (min-width: 769px) {
  .open-positions {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
    /* padding: 0 var(--spacing-2); */
  }

  .open-positions-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .open-positions-title {
    /* font-size: var(--font-size-2xl); */
    font-weight: var(--font-weight-bold);
    color: var(--text-primary);
  }
  
  .open-positions-updated-time {
    padding: 1rem;
    text-align: right;
    font-style: italic;
    color: var(--text-primary);
  }

  .table {
    display: grid;
    grid-template-columns: 1fr;
  }

  .open-positions-coinbase-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: var(--text-primary);
    font-style: italic;
  }

  .open-positions-no-data {
    margin-top: var(--spacing-2);
    text-align: center;
    color: var(--text-primary);
  }

  .MuiDataGrid-toolbarContainer {
    border: none;
    border-style: none;
  }

  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-footerContainer {
    background-color: var(--color-open-positions-table);
  }

  .MuiDataGrid-virtualScroller {
    overflow: hidden !important;
  }

  .MuiDataGrid-row {
    /* background-color: white !important;
    box-shadow: inset 0px 0px 10px 1px rgba(218, 221, 226, 0.4); */
    background: var(--glass-component-bg) !important; /* Light and semi-transparent */

    border: 1px solid rgba(255, 255, 255, 0.3); /* Optional border */

  }

  .--cell {
    /* font-size: 14px; */
  }

  .--token-cell {
    font-weight: var(--font-weight-semi-bold);
  }
}
