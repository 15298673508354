.dashboard-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-md);
  padding: 5px var(--spacing-2);
  color: var(--text-primary);
}

.dashboard-footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.dashboard-footer li:not(:last-of-type)::after {
  content: "|";
}

.dashboard-footer a {
  text-decoration: none;
  padding-right: 8px;
  color: var(--text-primary);
}

.dashboard-footer-copyright a {
  color: var(--color-blue);
  text-decoration: underline;
}


@media screen and (max-width: 768px) {
  .dashboard-footer {
    font-size: var(--font-size-xs);
    display: flex;
    flex-direction: column;
  }
}