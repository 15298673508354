.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-card {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 1rem 0rem;
  width: 95%;
  height: 50%;
  min-height: 165px;
  background-color: var(--background-secondary);
  box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  color: var(--text-primary);
}

.modal-card-title {
  padding: 1rem 0rem 0rem 1rem;
  margin: 0;
  /* font-size: var(--font-size-xl); */
}

.modal-card-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  margin-left: 1rem;
  height: 75%;
}

.modal-card-postion-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
}

.modal-card-position-value {
  margin: 0;
  padding: 0;
  font-size: 34px;
}

.modal-card-position-value-subtext {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-xl);
}

.modal-card-chart-display {
  display: flex;
  /* flex: 1 1 auto; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 769px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .modal-card {
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem 1rem 0rem;
    width: 50%;
    min-width: 265px;
    height: 50%;
    min-height: 165px;
    background-color: var(--background-secondary);
    box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    color: var(--text-primary);
  }
  
  .modal-card-title {
    padding: 1rem 0rem 0rem 1rem;
    margin: 0;
    /* font-size: var(--font-size-xl); */
  }
  
  .modal-card-info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 1rem;
    height: 75%;
  }
  
  .modal-card-postion-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
  }
  
  .modal-card-position-value {
    margin: 0;
    padding: 0;
    font-size: 34px;
  }
  
  .modal-card-position-value-subtext {
    margin: 0;
    padding: 0;
    font-size: var(--font-size-xl);
  }
  
  .modal-card-chart-display {
    display: flex;
    /* flex: 1 1 auto; */
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
  }
  
}