.cust-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid var(--color-grey);
    border-radius: 10px;
}

.cust-table-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-3);
    border-bottom: 1px solid var(--color-grey);
    color: var(--text-primary);
    font-weight: var(--font-weight-semi-bold);
}

.cust-row-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: var(--spacing-3);
    align-items: center;
    color: var(--text-primary);
}

.cust-row-wrapper:not(:last-child) {
    border-bottom: 1px solid var(--color-grey);
}

.cust-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0px;
}


.cust-row-title {
    display: flex;
    justify-content: flex-start;
    min-width: 0px;
    max-width: 100%;
    font-weight: var(--font-weight-semi-bold);
}

.cust-row-data {
    display: flex;
    justify-content: flex-start;
    word-break: break-word;
}

@media screen and (min-width: 1024px) {
    .cust-table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        border: 1px solid var(--color-grey);
        border-radius: 10px;
    }
    
    .cust-table-header {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: var(--spacing-3);
        border-bottom: 1px solid var(--color-grey);

        font-weight: var(--font-weight-semi-bold);
    }
    
    .cust-row-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: var(--spacing-3);
        align-items: center;
    }
    
    .cust-row-wrapper:not(:last-child) {
        border-bottom: 1px solid var(--color-grey);
    }
    
    .cust-row {
        display: flex;
        flex-direction: row;
        flex: 1 1 0%;
        justify-content: space-between;
        align-items: center;
        min-width: 0px;
    }
    
    
    .cust-row-title {
        display: flex;
        justify-content: flex-start;
        min-width: 0px;
        max-width: 100%;
        font-weight: var(--font-weight-semi-bold);
    }
    
    .cust-row-data {
        display: flex;
        justify-content: flex-end;
        word-break: break-word;
    }
}