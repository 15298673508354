.op-table {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
}

.op-table-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: var(--spacing-1);
    padding-left: var(--spacing-2);
    width: 100%;
    border-bottom: 1px solid var(--color-text-grey);
}

.op-table-header-container {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-1);
    color: var(--text-secondary);
}

.op-table-header p {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semi-bold);
    /* place-self: center; */
}

.op-row-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: var(--spacing-1);
    padding-left: var(--spacing-2);
    gap: var(--spacing-1);
    background-color: var(--table-background);
    width: 100%;

}

.op-row-wrapper:not(:last-child) {
    border-bottom: 1px solid var(--color-grey);
}

.op-value-container {
    gap: var(--spacing-1);
}

.op-token-container, .op-value-container, .op-profit-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    /* place-self: center; */
}

.op-token-amount-container {
    display: flex;
    flex-direction: row;
}

.op-row-title {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-regular);
    color: var(--text-primary);
}

.op-row-base {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    font-weight: var(--font-weight-medium);
}

.op-profit-pos {
    /* color: var(--color-trading-green); */
    color: green;
}

.op-profit-neg {
    /* color: var(--color-trading-red); */
    color: red;
}


/* Modal Styling */

.op-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: var(--background-primary);
}

.op-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-1);
    border-bottom: 1px solid var(--text-primary);
    color: var(--text-primary);
}

.op-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--spacing-2);
}

.op-modal-header-title {
    font-size: var(--font-size-2xl);
}

.op-modal-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--spacing-2);
}

.op-modal-row-title {
    font-size: var(--font-size-sm);
    color: var(--text-primary);
}

.op-modal-row-base {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
}