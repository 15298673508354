/* Mobile Styles */
@media screen and (max-width: 768px) {
  
  .dashboard-header {
    grid-area: header;
  }
  .sidebar {
    display: none;
  }
  
  .dashboard-layout-content {
    display: flex;
    flex-direction: column;
    background: var(--glass-background);
    flex-grow: 1;
    /* background-color: var(--background-primary); */
    /* padding: 0 var(--spacing-2); */
    margin-top: var(--spacing-4);
  }
  
  .dashboard-hamburger-button-container {
    position: fixed;
    top: var(--spacing-2);
    right: var(--spacing-2);
    z-index: 15;
  }
  
  /* un-used class with <header> */
  .dashboard-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* max-width: var(--breakpoint-xl); */
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 16px 0;
    overflow: hidden;
  }
  
  .dashboard-navigation h1 {
    margin: 0;
  }
  
  .dashboard-navigation li {
    list-style: none;
  }
  
  .mobile-side-container {
    position: fixed;
    top: 0px;
    opacity: 0;
    right: -100vw;
    /* Start off-screen */
    width: 100%;
    height: 100%;
    background-color: var(--background-secondary);
    /* color: var(--color-white); */
    box-sizing: border-box;
    transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    padding: 0;
    z-index: var(--max-z-index);
  }
  
  .mobile-side-container--open {
    right: 0;
    opacity: 1;
    overflow-y: scroll;
  }
  .mobile-header-nav {
    margin: 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px 0;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
  }
  
  .close-button-container {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 87px; /* header height*/
    padding: 0 16px;
  }
  
  @keyframes FadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .main-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  
  .dashboard-layout {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    
    /* min-width: 100vw; */
     /* keep footer pinned to bottom when loading a page and no content is showing*/
    animation: FadeIn 0.3s ease-in-out;
  }
  
  .topbar-container--desktop {
    display: none;
  }
}

/* Tablet Styles */

@media screen and (min-width: 769px) {
  .dashboard-layout {
    display: flex;
    flex-direction: row;
    background: var(--glass-background); /* Light and semi-transparent */
    /* background: var(--gradient-peachy); */
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  .dashboard-layout-circle {
    position: absolute;
    filter: blur(150px);
  }

  .circle1{
    background: var(--circle-background1); /* Light and semi-transparent */
    width: 35vw;
    height: 35vw;
    top: 10%;
    left: 10%;

  }

  .circle2{
    background: var(--circle-background2); /* Light and semi-transparent */
    width: 35vw;
    height: 35vw;
    bottom: 5%;
    right: 5%;
    
  }

  .circle3 {
    width: 25vw;
    height: 25vw;
    top: 0%;
    right: 0%;
    background-color: var(--circle-background3);
  }

  .dashboard-layout-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    /* max-width: var(--breakpoint-2xl); */
    /* background-color: var(--background-primary); */
    margin-top: 0;
    overflow-x: auto;
  }

  .dashboard-navigation {
    display: none;
  }

  .dashboard-hamburger-button-container {
    display: none;
  }

  .mobile-side-container {
    display: none;
  }

  .dashboard-navigation .title {
    display: none;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 16px;
    min-height: 100vh;
    /* background-color: var(--background-secondary); */
  }

  .sidebar-user {
    background: transparent;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 322px;
    padding: 16px;
  }

  .sidebar--minimized {
    width: 60px;
  }

  .sidebar--minimized .sidebar-user {
    width: 60px;
  }

  .sidebar--minimized .chartobserver-logo {
    display: none;
  }

  .material-icon {
    color: var(--text-primary);
  }

  .sidebar-header--expanded .material-icon {
    transform: rotate(-90deg);
    transition: all 0.2s ease-in-out;
  }

  .sidebar-header--minimized .material-icon {
    transform: rotate(-90deg);
    transition: all 0.2s ease-in-out;
  }

  .sidebar--minimized .material-icon {
    transform: rotate(90deg);
  }

  .sidebar--minimized .button {
    padding: 8px;
  }

  .sidebar-content {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 16px;
  }
  .sidebar-header--expanded {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    margin-bottom: 32px;
    font-size: var(--font-size-2xl) !important;
  }

  .sidebar-header--minimized {
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
    align-items: center;
    margin-bottom: 16px;
    font-size: var(--font-size-2xl) !important;
  }

  .topbar-container--mobile {
    display: none;
  }

  .topbar-container--desktop {
    display: flex;
    width: 100%;
    
  }

  .main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
