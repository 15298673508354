.profile-page-content {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-4);
    gap: var(--spacing-4);
    width: 70%;
    justify-content: center;
    align-self: center;
}

.profile-page-username-container {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
}

.profile-page-user-info {
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-grow: 1;
}

.profile-page-user-info-tv {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-primary-a0);
    cursor: pointer;
}

.profile-page-social-container {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-1);
}