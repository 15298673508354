.dashboard-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* padding: var(--spacing-2); */
}

.dashboard-grid-container {
    width: 100%;
    /* background-color: blanchedalmond; */
    padding: var(--spacing-4);
}

.dashboard-grid {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: auto auto; */
    gap: var(--spacing-4);
}

.dashboard-grid-row-1 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: var(--spacing-4);
}

.dashboard-grid-row-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: var(--spacing-4);
}

.mobile-table-updated {
    text-align: right;
    margin-right: var(--spacing-1);
    font-size: var(--font-size-xxs);
    color: var(--text-primary);
}

.mobile-op-title {
    display: flex;
    flex-grow: 1;
    border-top: 1px solid var(--color-grey);
    color: var(--text-primary);
    padding: var(--spacing-2);
}

.dashboard-content-api-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: italic;
    flex-grow: 1;
    padding: var(--spacing-2);
    color: var(--text-primary);
}

@media screen and (min-width: 769px) {
    .dashboard-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: var(--spacing-2);
    }
}