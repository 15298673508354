.contact-us {
    display: flex;
    flex-direction: column;
    flex-grow: 10;
    align-items: center;
}

.contact-us-content {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    /* height: 100%; */
    width: 80%;
}

.contact-us-content p {
    margin: 20px;
    font-size: var(--font-size-base);
    color: var(--text-primary);
}

.contact-us-email-address {
    color: var(--color-blue);
}

@media screen and (min-width: 769px) {
    .contact-us {
        display: flex;
        flex-direction: column;
        flex-grow: 10;
        align-items: center;
    }
    
    .contact-us-content {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        /* height: 100%; */
        width: 60%;
    }
    
    .contact-us-content p {
        margin: 20px;
        font-size: var(--font-size-base);
        color: var(--text-primary);
    }
    
    .contact-us-email-address {
        color: var(--color-blue);
    }
}